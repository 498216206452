export default [
  {
    title: "Dashboard",
    route: "dashboard",
    icon: "HomeIcon",
  },
  {
    title: "Ondemand",
    route: "",
    icon: "TrendingUpIcon",
    children: [
      {
        title: "Create Order",
        route: "on-demand-delivery",
        icon: "SendIcon",
        action: "manage",
      },
      {
        title: "Orders",
        route: "",
        icon: "ClipboardIcon",
        action: "manage",
      },
    ],
  },
  {
    title: "Distributor",
    route: "",
    icon: "ShoppingCartIcon",
    children: [
      {
        title: "Create Order",
        route: "distributor-bulk-delivery",
        icon: "SendIcon",
        action: "manage",
      },
      {
        title: "Orders",
        route: "",
        icon: "ClipboardIcon",
        action: "manage",
      },
    ],
  },
  {
    title: "DHL",
    route: "",
    icon: "TruckIcon",
    children: [
      {
        title: "Create Order",
        route: "DHL-delivery",
        icon: "SendIcon",
        action: "manage",
      },
      {
        title: "Orders",
        route: "",
        icon: "ClipboardIcon",
        action: "manage",
      },
    ],
  },
  {
    title: "Log Points",
    route: "log-points",
    icon: "SearchIcon",
  },
];
